.FeatureBar_root___INU8 {

  position: fixed;

  bottom: 0px;

  z-index: 30;

  width: 100%;

  gap: 2rem;

  background-color: var(--primary);

  padding: 1.5rem;

  font-size: 0.875rem;

  line-height: 1.25rem;

  font-weight: 500;

  transition-property: all;

  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  transition-duration: 300ms;

  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

  box-shadow: 60px 0 40px -7px rgba(0, 0, 0, 0.2)
}

